import { App } from '@capacitor/app'
import { AppLauncher } from '@capacitor/app-launcher'
import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Dialog } from '@capacitor/dialog'
import { FCM } from '@capacitor-community/fcm'
import { InstallMode, codePush } from 'capacitor-codepush'
import { Keyboard } from '@capacitor/keyboard'
import { PushNotifications } from '@capacitor/push-notifications'
import { SplashScreen } from '@capacitor/splash-screen'
import { matchPath } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import semver from 'semver'

import { actions, useSelector } from '@/redux'
import { deploymentKeysToDeploymentMap, getDeployment, statusMessages } from '@/constants/codePush'
import { getPaymentTypeByReturnUrl } from '@/libs/payments'
import { isPoonChoiCategoryTag } from '@/libs/poonchoi'
import { remoteConfig } from '@/libs/firebase'
import StorageKey from '@/constants/storageKey'
import URLParser from '@/libs/URLParser'
import config from '@/config'
import constants from '@/constants'
import customerApi from '@/libs/api/customer'
import dimorderApi from '@/libs/api/dimorder'
import history from '@/libs/history'
import i18n from '@/i18n'
import landingApi from '@/libs/api/landing'
import logger, { flush, logId } from '@/libs/logger'
import openBrowser from '@/libs/openBrowser'
import timer from '@/libs/timer'
import vConsole from '@/libs/vConsole'

import { initialParams } from './reducer'
import { useMerchant } from '@root/src/hooks/merchant'
import ActionTypes from './ActionTypes'
import PaymentGateway from '@/constants/paymentGateway'

/** @typedef {import('@/redux/app/AppState.d').IAlertConfig} IAlertConfig */
/** @typedef {import('@/redux/app/AppState.d').IDatetime} IDatetime */
/** @typedef {import('@/redux/app/AppState.d').IDialog} IDialog */
/** @typedef {import('@/redux/app/AppState.d').IDrawer} IDrawer */
/** @typedef {import('@/redux/app/AppState.d').IModal} IModal */
/** @typedef {import('@/redux/app/AppState.d').IParams} IParams */
/** @typedef {import('@/redux/app/AppState.d').IPopover} IPopover */
/** @typedef {import('@/redux/app/AppState.d').ISnackbar} ISnackbar */
/** @typedef {import('dimorder-orderapp-lib/dist/types/PromoCode').IMarketingAction}  IMarketingAction */

const { TAKEAWAY, STORE_DELIVERY, TABLE } = constants.deliveryType
const { CREDIT_CARD, PAY_ME } = constants.paymentMethod

/**
 * 檢查 merchant.setting.dineInForceUsingApp 若強制使用 App 內用則前往 app.dimorder.com
 * @returns {ThunkFunction}
 */
export function checkDineInForceUsingAppAndRedirect () {
  return (dispatch, getState) => {
    const params = getState().app.params
    const dineInForceUsingApp = getState().merchant.data?.setting?.dineInForceUsingApp

    if (Capacitor.isNativePlatform()) return false // 已經在 App 不需要 redirect
    if (params.deliveryType !== TABLE) return false // 非堂食
    if (navigator.userAgent.includes('Android')) return false // Android App 很卡，不特別推薦使用
    if (!dineInForceUsingApp) return false // ! 沒限使用 App

    // 準備把 params 帶入 app
    const newParams = {
      init: true,
      appOnly: true,
    }
    for (const key in params) {
      // datetime 和 undefined 的值不用帶過去
      if (params[key] != null && key !== 'datetime') {
        newParams[key] = params[key]
      }
    }
    const searchParams = new URLSearchParams(newParams)
    window.location.href = `${config.universalBaseUrl}?${searchParams.toString()}`
    return true
  }
}

/**
 * 檢查 merchant.setting.dineInPayOnAppOnly 若堂食強制使用 App 付款則前往 app.dimorder.com/pay
 * @returns {ThunkFunction}
 */
export function checkDineInPayOnAppOnlyAndRedirect () {
  return (dispatch, getState) => {
    const params = getState().app.params
    const dineInPayOnAppOnly = getState().merchant.data?.setting?.dineInPayOnAppOnly

    if (Capacitor.isNativePlatform()) return false // 已經在 App 不需要 redirect
    if (params.deliveryType !== TABLE) return false // 非堂食
    if (navigator.userAgent.includes('Android')) return false // Android App 很卡，不特別推薦使用
    if (!dineInPayOnAppOnly) return false // ! 沒限制堂食只能用 App 付款

    // 準備把 params 帶入 app
    const newParams = {
      appOnly: true,
    }
    for (const key in params) {
      // datetime 和 undefined 的值不用帶過去
      if (params[key] != null && key !== 'datetime') {
        newParams[key] = params[key]
      }
    }
    const searchParams = new URLSearchParams(newParams)
    window.location.href = `${config.universalBaseUrl}?${searchParams.toString()}`
    return true
  }
}

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    timer.time('init')
    logger.log('[init][0/10] start')
    dispatch(updateLoading('backdrop', true))

    window.QRCodeScanUrl = (url) => {
      dispatch(handleQRCodeData(url))
    }

    timer.time('getDeviceInfo')
    await dispatch(getDeviceInfo())
    timer.timeEnd('getDeviceInfo')

    timer.time('checkAppUpdate')
    const isAppUpdate = await dispatch(checkAppUpdate())
    timer.timeEnd('checkAppUpdate')

    logger.log(`[init][1/10] checkAppUpdate Done. update: ${Boolean(isAppUpdate)}`)
    dispatch(codePushSync(false))
    logger.log('[init][2/10] codePushSync Done.')

    let initTimeout = null
    try {
      dispatch(updateLoading('backdrop', true))
      let clipboardUrl
      // FIXME: 剪貼簿沒東西時會 crash (plugin native bug)
      // try {
      //   const clipboardValue = await clipboardRead()
      //   if (URLParser.isValidSite(clipboardValue)) {
      //     clipboardUrl = clipboardValue
      //   }
      // } catch (error) {
      //   logger.error(`Clipboard.read() error ${error?.message || error}`, { error })
      // }
      // logger.log(`Clipboard ok, clipboardUrl ${clipboardUrl}`, { clipboardUrl })

      initTimeout = setTimeout(() => {
        SplashScreen.hide()
        logger.error('[init] error timeout')
        dispatch(toggleAlert({
          title: i18n.t('app.component.alert.init_failed.title'),
          message: i18n.t('app.component.alert.init_failed.message') + ` (${logId})`,
          dialogProps: { disableBackdropClick: true },
          button: {
            text: i18n.t('app.common.confirm'),
            onClick: () => {
              // 取得要保留的內容
              const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
              const address = localStorage.getItem('address')
              // clear location storage
              localStorage.clear()
              localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
              localStorage.setItem('address', address)
            },
          },
        }))
      }, 20000)

      await dispatch(setKeyboardMode())
      logger.log('[init][3/10] setKeyboardMode() Done.')

      timer.time('startAppListener')
      await dispatch(startAppListener())
      timer.timeEnd('startAppListener')
      logger.log('[init][4/10] startAppListener() Done.')

      timer.time('getPaymentGateway')
      await dispatch(actions.app.getPaymentGateway())
      timer.timeEnd('getPaymentGateway')
      logger.log('[init][5/10] getPaymentGateway() Done.')

      timer.time('user.init')
      await dispatch(actions.user.init())
      timer.timeEnd('user.init')
      logger.log('[init][6/10] user.init() Done.')

      timer.time('orderHistory.init')
      await dispatch(actions.orderHistory.init())
      timer.timeEnd('orderHistory.init')
      logger.log('[init][7/10] orderHistory.init() Done.')

      timer.time('restoreParams and other')
      await dispatch(restoreParams(clipboardUrl))
      await dispatch(actions.payment.init())
      await dispatch(updateSystemDeliveryTypeEnable())
      timer.timeEnd('restoreParams and other')
      logger.log('[init][8/10] restoreParams() ... Done.')

      dispatch(updateLoading('backdrop', false))
      dispatch({ type: ActionTypes.INIT, payload: {} })

      SplashScreen.hide()
      logger.log('[init][9/10] complete, SplashScreen.hide()')

      // 若有 pendingMarketingAction 且 landing 已經 init，處理後移除
      const landingIsInit = getState().landing.isInit
      const pendingMarketingAction = getState().app.pendingMarketingAction
      if (landingIsInit && pendingMarketingAction) {
        dispatch(actions.app.handleMarketingAction(pendingMarketingAction.type, pendingMarketingAction.payload))
        dispatch(actions.app.removePendingMarketingAction())
      }
    } catch (error) {
      SplashScreen.hide()
      logger.error(`[init] error ${error?.message || error}`, { error })
      dispatch(toggleAlert({
        title: i18n.t('app.component.alert.init_failed.title'),
        message: i18n.t('app.component.alert.init_failed.message') + ` (${logId})`,
        dialogProps: { disableBackdropClick: true },
        button: {
          text: i18n.t('app.common.confirm'),
          onClick: async () => {
            // 取得要保留的內容
            const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
            const address = localStorage.getItem('address')
            // clear location storage
            localStorage.clear()
            localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
            localStorage.setItem('address', address)

            // 將 gcp log 剩下還沒送出的 log 都送出才 reload
            await flush()
            // reload
            document.location.href = '/'
          },
        },
      }))
    } finally {
      clearTimeout(initTimeout)
      timer.timeEnd('init')
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function getDeviceInfo () {
  return async (dispatch, getState) => {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    const appInfo = await App.getInfo()
    console.log('App.getInfo', appInfo)
    const { uuid: deviceId } = await Device.getId()
    console.log('Device.getId', deviceId)
    const deviceInfo = await Device.getInfo()
    console.log('Device.getInfo', deviceInfo)

    dispatch({
      type: ActionTypes.UPDATE_APP_INFO,
      payload: { appInfo },
    })
    dispatch({
      type: ActionTypes.UPDATE_DEVICE_INFO,
      payload: { deviceId, deviceInfo },
    })
  }
}

/**
 * CA-1283 檢查使用者的時區，若不在 +8 則禁止使用
 * @returns {ThunkFunction}
 */
export function checkTimeZone () {
  return async (dispatch, getState) => {
    const timezone = new Date().toString().match(/([A-Z]+[+-][0-9]+)/)[1]
    const isTimeZoneError = getState().app.isTimeZoneError
    if (
      timezone !== 'GMT+0800' &&
      !isTimeZoneError // 已經 isTimeZoneError 了，表示已經有處理過，不用再顯示一次 alert
    ) {
      // 設定一個 flag isTimeZoneError = true，避免 SplashAD 開啟
      dispatch({ type: ActionTypes.SET_TIME_ZONE_ERROR })
      // 若已經有顯示 Splash AD 先關閉
      dispatch(actions.app.toggleDialog('splashAd', false, {}))
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.timezone_error.title', { timezone }),
        message: i18n.t('app.component.alert.timezone_error.message'),
        button: {
          text: i18n.t('app.component.alert.timezone_error.reload'),
          onClick: async () => {
            // 將 gcp log 剩下還沒送出的 log 都送出才 reload
            await flush()
            // reload
            document.location.href = '/'
          },
        },
        dialogProps: { disableBackdropClick: true },
      }))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function checkAppUpdate () {
  return async (dispatch, getState) => {
    try {
      if (!Capacitor.isNativePlatform()) {
        console.log('[CheckStoreUpdate] skip, not native')
        return false
      }

      const appVersion = getState().app.appInfo?.version
      const appBuild = getState().app.appInfo?.build
      if (!appVersion) {
        console.log('[CheckStoreUpdate] skip, no app version')
        return false
      }

      logger.log('[CheckStoreUpdate] checkAppUpdate start')

      const platform = Capacitor.getPlatform()
      await remoteConfig.fetchAndActivateWithTimeout()
      const minVersion = remoteConfig.getValue(`customer_app_min_version_${platform}`).asString()
      const latestVersion = remoteConfig.getValue(`customer_app_latest_version_${platform}`).asString()

      logger.log(`[CheckStoreUpdate] appVersion: ${appVersion}, minVersion: ${minVersion}, latestVersion: ${latestVersion}`, {
        appVersion,
        appBuild,
        minVersion,
        latestVersion,
      })

      const needUpdate = semver.lt(appVersion, minVersion)
      logger.log('[CheckStoreUpdate] app update needUpdate: ' + needUpdate)
      const askUpdate = semver.lt(appVersion, latestVersion)
      logger.log('[CheckStoreUpdate] app update askUpdate: ' + askUpdate)

      if (config.env !== 'prod') {
        console.log('[CheckStoreUpdate] skip, not prod env')
        return false
      }
      if (needUpdate) {
        // 無限 alert，不要讓他繼續 init app
        const showAlert = async () => {
          // TODO: i18n
          await Dialog.alert({
            title: 'APP 需要更新',
            message: '請在商店更新至最新版本',
            buttonTitle: '前往更新',
          })

          AppLauncher.openUrl({ url: config.storeUrl })
          return showAlert()
        }
        await showAlert()
        return true
      }

      if (askUpdate) {
        // TODO: i18n
        const result = await Dialog.confirm({
          title: 'APP 有新版本',
          message: '是否要前往更新？',
          okButtonTitle: '立即更新',
          cancelButtonTitle: '下次再說',
        })

        if (result.value) {
          AppLauncher.openUrl({ url: config.storeUrl })
        }
      }
      return false
    } catch (error) {
      logger.error(`[CheckStoreUpdate] checkAppUpdate error ${error.message || error}`, { error })
      return false
    }
  }
}

export function codePushSync (showUpdateDialog = false) {
  return async (dispatch, getState) => {
    // android 無法 codepush，web 沒有 codepush，只有 ios 要 sync
    if (Capacitor.getPlatform() !== 'ios') return
    timer.time('codePushSync')
    logger.log('[CodePush] codePushSync start.')

    try {
      timer.time('codePushSync/getCurrentPackage')
      const codePushPackageMeta = await codePush.getCurrentPackage()
      timer.timeEnd('codePushSync/getCurrentPackage')
      logger.log('[CodePush] getCurrentPackage', { codePushPackageMeta })
      if (codePushPackageMeta) {
        dispatch({
          type: ActionTypes.UPDATE_PACKAGE_META,
          payload: {
            codePushPackageMeta: {
              ...codePushPackageMeta,
              deployment: deploymentKeysToDeploymentMap[codePushPackageMeta.deploymentKey],
            },
          },
        })
      }
    } catch (error) {
      logger.warn(`[CodePush] getCurrentPackage error ${error.message || error}`)
    }

    try {
      logger.log(`[CodePush] sync with deployment: ${config.deployment} (${config.deploymentKey})`)
      timer.time('codePushSync/sync')

      const updateDialogOptions = {
        updateTitle: i18n.t('app.codepush.updateDialog.updateTitle'),
        mandatoryUpdateMessage: i18n.t('app.codepush.updateDialog.mandatoryUpdateMessage'),
        mandatoryContinueButtonLabel: i18n.t('app.codepush.updateDialog.mandatoryContinueButtonLabel'),
      }
      const status = await codePush.sync(
        {
          installMode: InstallMode.IMMEDIATE,
          deploymentKey: config.deploymentKey,
          updateDialog: updateDialogOptions,
        },
        (progress) => {
          const progressPercent = (100 * progress.receivedBytes / (progress.totalBytes || 1)).toFixed(0)
          logger.log(`[CodePush] download progress: ${progressPercent}% (${progress.receivedBytes} / ${progress.totalBytes})`, { progress })
        },
      )
      timer.timeEnd('codePushSync/sync')
      logger.log(`[CodePush] codePushSync complete. sync status: ${status} ${statusMessages[status]}`)
      if (showUpdateDialog) {
        dispatch(actions.snackbar.enqueueSnackbar({
          message: statusMessages[status],
        }))
      }
    } catch (error) {
      logger.error(`[CodePush] codePushSync error ${error.message || error}`, { error })
    } finally {
      timer.timeEnd('codePushSync')
    }
  }
}

/**
 * mode 不給的話預設 'native' （鍵盤不會覆蓋到畫面）
 * @param { 'body' | 'ionic' | 'native' | 'none' | undefined } mode
 * @returns {ThunkFunction}
 */
export function setKeyboardMode (mode) {
  return async (dispatch, getState) => {
    if (Capacitor.getPlatform() === 'ios') {
      // 'body' | 'ionic' | 'native' | 'none'
      // 文件沒寫是幹麻的
      // 測試起來 body = ionic = none 就是高度不會變，鍵盤蓋上面
      // native 是預設值，鍵盤出現時畫面會變小，導致 UI 可能會有狀況，所以目前先設 none
      await Keyboard.setResizeMode({
        // mode: 'none',
        mode: mode ?? 'native', // 改成 native 不然備註輸入框會被鍵盤蓋掉
      })
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function startAppListener () {
  return async (dispatch, getState) => {
    // * 以下皆為 Native 功能，非 Native 直接 return
    if (!Capacitor.isNativePlatform()) return

    App.addListener('appStateChange', (appState) => {
      console.log('appStateChange', appState)
      // 每次 App resumes 檢查更新
      if (appState.isActive) {
        dispatch(codePushSync(false))
      }
    })

    PushNotifications.addListener('pushNotificationReceived', notification => {
      logger.log('[pushNotificationReceived]', { notification })
      // APP 在前景時收到推播，目前沒有要處理
    })
    PushNotifications.addListener('pushNotificationActionPerformed', actionPerformed => {
      logger.log('[pushNotificationActionPerformed]', { actionPerformed })

      // 推播被點開時
      if (actionPerformed.notification?.data?.type === 'ACTION') {
        const action = JSON.parse(actionPerformed.notification?.data?.payload ?? null)

        // app init 過程中可能會 redirect，因此要等 app init 完成後再處理 marketing action
        // marketing action 很多都需要 landing 的 google sheet 資料，若 landing 還沒完成 init，先將 marketingAction pending，待 landing.init 完成後再處理
        const appIsInit = getState().app.isInit
        const landingIsInit = getState().landing.isInit
        if (appIsInit && landingIsInit) {
          dispatch(handleMarketingAction(action?.type, action?.payload))
        } else {
          dispatch(addPendingMarketingAction(action))
        }
      }
    })

    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        dispatch(actions.orderHistory.startOrdersUpdater())
      } else {
        dispatch(actions.orderHistory.stopOrdersUpdater())
      }
    })

    App.addListener('appUrlOpen', async (data) => {
      if (config.env !== 'prod') {
        dispatch(actions.snackbar.enqueueSnackbar({
          message: `appUrlOpen: ${JSON.stringify(data)}`,
        }))
      }

      // 若是從第三方付款回來 APP 的從 url 判斷是哪種付款方式
      const url = new URL(data.url)
      const paymentType = getPaymentTypeByReturnUrl(url)

      logger.log(`appUrlOpen ${data.url}`, { url: data.url, paymentType })

      if ([
        constants.paymentMethod.PAY_ME,
        constants.paymentMethod.WECHAT_PAY,
        constants.paymentMethod.ALI_PAY,
        constants.paymentMethod.FPS,
        constants.paymentMethod.OCTOPUS,
      ].includes(paymentType)) {
        // 若是從第三方付款回來，因為不需要 redirect，不要 restoreParams
        return
      }

      if (URLParser.isValidSite(data.url)) {
        dispatch(restoreParams(data.url))
      }
    })

    // 禁止用 Back button 回上頁的頁面
    const disableBackUrls = [
      '/',
      '/login',
    ]
    // Back button 需要做特別處理的頁面
    const backHandlerConfigs = [
      // {
      //   url: '/',
      //   checkState: () => {},
      //   handler: () => {},
      // },
    ]

    App.addListener('backButton', () => {
      const alerts = getState().app.alerts
      if (_.find(alerts, alert => alert.open)) {
        // 有打開的 alert 就全部關掉
        dispatch({
          type: ActionTypes.RESET_ALERTS,
        })
        return
      }
      const dialogs = getState().app.dialogs
      if (_.find(dialogs, (dialog) => dialog.open)) {
        // 有打開的 dialog 就全部關掉
        dispatch({
          type: ActionTypes.RESET_DIALOGS,
        })
        return
      }
      const drawers = getState().app.drawers
      if (_.find(drawers, (drawer) => drawer.open)) {
        // 有打開的 drawer 就全部關掉
        dispatch({
          type: ActionTypes.RESET_DRAWERS,
        })
        return
      }

      const url = history.location.pathname

      const handlerConfig = backHandlerConfigs.find(backHandlerConfig => {
        return backHandlerConfig.url === url && backHandlerConfig.checkState()
      })
      if (handlerConfig) {
        handlerConfig.handler()
        return
      }
      if (!disableBackUrls.includes(url)) {
        history.goBack()
      }
    })
  }
}

export const initPushNotification = () => {
  return async (dispatch, getState) => {
    // only use in native app
    if (!Capacitor.isNativePlatform()) return
    logger.log('[initPushNotification] start')

    try {
      let permissionStatus = await PushNotifications.requestPermissions()
      if (permissionStatus.receive === 'prompt') {
        permissionStatus = await PushNotifications.requestPermissions()
      }
      if (permissionStatus.receive !== 'granted') {
        logger.log('[initPushNotification] permission not granted, skip')
        return
      }
    } catch (error) {
      logger.error(`[initPushNotification] requestPermissions() error ${error?.message || error}`, { error })
    }

    PushNotifications.addListener('registration', async (registrationResult) => {
      logger.log('[initPushNotification] registration listener called', { registrationResult })
      try {
        let token = registrationResult.value
        if (Capacitor.getPlatform() === 'ios') {
          logger.log('[initPushNotification] ios FCM.getToken()')
          const result = await FCM.getToken()
          logger.log('[initPushNotification] ios FCM.getToken() result', { result })
          token = result.token
        }
        if (token) {
          const { uuid: deviceId } = await Device.getId()
          logger.log('[initPushNotification] registerFcmToken()', { deviceId, token })
          await customerApi.registerFcmToken(deviceId, token)
        }
      } catch (error) {
        logger.error(`[initPushNotification] registration error ${error?.message || error}`, { error })
      }
    })

    // registers device on FCM server
    logger.log('[initPushNotification] register()')
    await PushNotifications.register()
  }
}

/**
 * @returns {ThunkFunction}
 */
export function stopAppListener () {
  return (dispatch) => {
    console.log('stopAppListener()')
    App.removeAllListeners()
    dispatch(actions.orderHistory.stopOrdersUpdater())

    if (!Capacitor.isNativePlatform()) return
    PushNotifications.removeAllListeners()
  }
}

/**
 * 檢查餐廳有沒有開，如果沒開的話顯示 preorder drawer
 * @returns {ThunkFunction}
 */
export function openPreorderDrawer () {
  return async (dispatch, getState) => {
    const merchant = getState().merchant.data
    const categoryTag = getState().app.params.categoryTag
    const datetime = getState().app.params.datetime
    const deliveryType = getState().app.params.deliveryType
    const isPoonchoi = isPoonChoiCategoryTag(categoryTag)
    const datetimeDrawerOpen = getState().app.drawers.datetime.open

    if (isPoonchoi) return // 盆菜不顯示 preorder
    if (datetimeDrawerOpen) return // 已經開啟選時間的 drawer，不需要在開啟預約按鈕的 drawer

    // 非營業時間打開 preorder drawer
    const restaurant = getState().landing.restaurant

    // 外帶外送 createShipping 之後會檢查 datetime available，若不可用會嘗試使用即時 timeslot 若沒有即時 timeslot或還是不可用就會將 datetime.date, datetime.time 改成 undefined
    // 因此外帶外送沒有 datetime.time 表是需要打開 preorder drawer
    // 另外當 /landing 抓不到餐廳也開啟 preorder drawer
    if ((deliveryType !== TABLE && !datetime.time) || !restaurant) {
      logger.log('[openPreorderDrawer] datetime unavailable', { restaurant, merchant, datetime })
      dispatch(actions.app.toggleDrawer('preorder', true))
    }
  }
}

/**
 * @param {String} merchantId
 * @returns {ThunkFunction}
 */
export function restaurantInit (merchantId) {
  return async (dispatch, getState) => {
    const params = getState().app.params
    const appLang = getState().app.lang
    const selectedOrder = getState().order.selectedOrder

    logger.log('[restaurantInit][0/6] restaurantInit() Start.', { merchantId, params, selectedOrder })
    timer.time('restaurantInit')

    if (!merchantId) {
      // 如果沒有給 merchantId，跳過
      logger.warn('[restaurantInit] error no merchantId, skip restaurantInit')
      return
    }

    dispatch(actions.app.updateLoading('restaurantInit', true))

    // dispatch(actions.app.updateLoading('backdrop', true))
    dispatch(actions.merchant.reset())
    dispatch(actions.order.updateShipping(undefined))

    try {
      if (merchantId !== params.merchantId) {
        const updatedParams = {
          ...params,
          merchantId: merchantId, // 將 merchantId 存入 params
          orderId: undefined, // 清除 orderId
          table: undefined, // 清除 table
        }

        if (params.reloadGateway) {
          updatedParams.reloadGateway = false

          // reset merchant payment gateway
          await dispatch(actions.app.getPaymentGateway())
        }

        // 選擇的餐廳和 params 中還原的餐廳不同
        dispatch(updateParams(updatedParams))

        // 清除 batch
        dispatch(actions.orderBatch.resetBatch())
      }

      const merchantIds = [
        '8b8ec5c5-f2b3-4d8b-83b1-2272ef3ee81d', // 魚一壽司
        'd432eea7-e6b1-44e3-8cb1-ce4090fdcee6', // 德記外賣美食小店
        '772b45ca-be6f-4b6d-b25c-3ecdadc48386', // 上品火鍋料理
        '2fa72439-7ea2-4d6a-b577-073fd103006e', // Coffee
        '559a8115-1bc7-4224-b53b-224a08d84b84', // 串串甜
        '45038b37-9063-4626-95a7-2fff1ab0968f', // 金壘餐廳

        'c09d8a16-78a4-4b76-9e4a-5daeba2dee19',
        'a5d9de30-fa79-42bd-b6a8-a2e4fc8a0953',
        '0c66c265-42f6-4b1d-8f43-711cd8747453',
        '20763f86-078a-4623-a355-f0e83440ec79',

        'f2223a92-e7de-4652-aa8b-078399951dfb',
        '54655f86-c626-47c8-99b2-6aac3f95f0c1',
        '44a11ac3-3f81-4308-ba2f-e173162b46a1',
        'e61df603-eb45-47f9-b229-b7df03f667d4',
        '1cebbb38-f869-46cd-9390-3b8b74b4d372',
        '6acc0daf-f4c8-4876-863c-aa7dbf8ed158',
        '73b2503e-3627-4f1a-8f68-f228bedeb211',
        '2689d425-cf63-41de-af1b-766b2cd9869a',
        'e8f2cffc-c433-4a33-ac75-7c50bc5f6662',
        '429e35b2-9311-4b4c-9903-bed5ad74fc6a',
        '0adbe09d-a277-4c3e-ab50-d9f8a4d48869',
        'f10dac6d-fdfc-4a79-b99f-66779580831a',
        '8ad10ced-0ed5-4456-aa00-68118d25c29f',
        'dfb8d0fe-3b72-4d1a-9073-72e1d1642443',
        'a8459e3a-963c-422c-af46-7d071ac1c36d',
        '35d15afc-741d-45c0-b157-ab5d93c51bec',
        'c95b1991-3107-4554-9fa9-dd0df2c1a862',
        'f82ece71-c09e-43fa-88eb-48ab1d20b7fd',

        '97549ff7-5795-491d-a5df-6e3df229e599',
        'c5708197-493a-4162-b7a1-58091af1c76f',
        '82840f74-c5c3-496b-a30f-28a3e3edc813',
        '7e822567-dabe-4213-8f52-bb49e108c5c1',
        '8b35453a-0319-43a5-9821-3ff25e01cd20',
        '5c1f43cc-b240-4025-b003-5df3f3958ce6',
        'a06203c9-951c-4f1f-95a1-53d78135f220',
        '9bfb6409-415a-4699-a7d0-84cb7035857e',

        '12ecb01c-ca8f-4881-a2bc-3ebc0e8a9736',

        '2720d421-a57b-4ed3-9525-9b6247201c12',
        '1462f608-df72-464f-a6c6-f49d12efc8be',

        'be4756a0-378c-4855-b16e-6aa81b73ec05',
        '9858087d-409a-43da-912e-6c3df98e6e61',
        'b2415ea1-3ee6-405a-9bbe-d716ce0f5ee7',
        'a025e7ab-4506-4841-bd32-5b73416c998c',
        'd5201a1c-de92-4df5-b7f5-e0bf631b154e',
        '6f3a0f28-e4cc-4163-833b-2cc90080e597',
        'e6be91a1-923c-4ca7-bc6b-b18b7ab0b969',
        '4aa2237b-a6a7-4a63-9df5-9eacf8c98500',
        'c09d8a16-78a4-4b76-9e4a-5daeba2dee19',
        'a5d9de30-fa79-42bd-b6a8-a2e4fc8a0953',
        '97549ff7-5795-491d-a5df-6e3df229e599',
        '8b8ec5c5-f2b3-4d8b-83b1-2272ef3ee81d',
        '0c66c265-42f6-4b1d-8f43-711cd8747453',
        '20763f86-078a-4623-a355-f0e83440ec79',
        'c5708197-493a-4162-b7a1-58091af1c76f',
        '80febe8d-4df3-4f8c-b10a-0d5d126398b1',
        '82840f74-c5c3-496b-a30f-28a3e3edc813',
        '699a211d-e229-45e1-a615-6dba991f254b',
        '7e822567-dabe-4213-8f52-bb49e108c5c1',
        'd0bcc74d-c590-41ef-b18d-97e10fe6c730',
        'e9b1554f-b82c-4cea-8606-d467abd8daca',
        '797f4d13-bbc9-48ab-aa1f-666f776b6ece',
        '8b35453a-0319-43a5-9821-3ff25e01cd20',
        'bbc93fa3-9c87-49d9-b4af-a92ecf917e25',
        '5c1f43cc-b240-4025-b003-5df3f3958ce6',
        'a06203c9-951c-4f1f-95a1-53d78135f220',
        '9bfb6409-415a-4699-a7d0-84cb7035857e',
        'd432eea7-e6b1-44e3-8cb1-ce4090fdcee6',
        '772b45ca-be6f-4b6d-b25c-3ecdadc48386',
        '2fa72439-7ea2-4d6a-b577-073fd103006e',
        '559a8115-1bc7-4224-b53b-224a08d84b84',
        '45038b37-9063-4626-95a7-2fff1ab0968f',
        '8ba34b4f-e7c7-4f5f-9241-5349062f099f',
        '2e4a764b-26bf-4d86-80a4-3b027f74b6cb',
        'f50f6a80-c394-407e-b366-f3d01be7a62f',
        'b0e3001b-5453-4399-b05a-f5f14067fa25',
        'b0aa780a-f3f0-4f11-babd-4eb8dd1137a1',
        '831daeb2-1e84-442b-a870-8bc539ae6563',
        'd0f44d3f-cc09-46d2-918b-0fcf23feb2a0',
        'aa504a02-3232-4df2-9b67-c87761f81c6e',
        '3def2346-ac85-4b8f-bd1a-75730b385f9c',
        'b1a04975-e279-4e15-86ec-42bbd4cf45ba',
        '9ba3960b-cbca-4eca-8477-ad3027daea78',
        '98ac61f1-c383-4df8-b4b0-fc83efdab372',
        'e575a9e6-19d0-4f25-aa3e-691df475022d',
        '542c0e45-43f2-4269-8c5a-c2519f3b267a',
        '10e2a2d0-4c4f-4e27-9a98-8307e5a440dc',
        '31cfda4e-c530-44f8-85de-3eb2360994b3',
        '8f72ca4e-0e95-4a76-84a0-8c7e6db388d0',
        'b2b462fa-adcb-4cef-8571-de8a1b4fac9c',
        'f2223a92-e7de-4652-aa8b-078399951dfb',
        '31541a2a-59b4-49ee-a932-f80e5ff5b67e',
        '332a98af-dba1-4f2f-9f96-5b9279d070e9',
        'bf8ba995-b621-4bb1-a495-cab4b20c45dd',
        '7f1ebb3e-ad9b-491c-b434-54cd6ce69b93',
        'b73ad917-4094-4fc7-a8e8-7333ff89099a',
        '81376e8a-1c7a-4c96-9797-1a13147dc64a',
        '38e92a08-a7d3-44d4-bdc9-415089f01fc8',
        '7476d8f4-e6c2-4ee8-ab31-7641aa8d82ac',
        '3895aa14-ad03-4afe-9c6b-9d8515bd001a',
        '9c921441-00f1-40ae-a4b3-c61ef790d500',
        '1e408d29-53cc-4647-ab0b-1a11c21717be',
        '269cdc07-842b-406a-8bfb-463ef41fdad5',
        '6ada0167-f629-4502-8ac4-d8b4594ca1e8',
        '0e453584-0de4-45e1-b37b-140a9b22fd4f',
        '3b1fc7fb-3d76-435a-a35d-4ef15d594eac',
        'a184971f-89ca-4d9e-8f52-6a18c2394dac',
        '74400b0a-789a-4072-9243-81930bf3f7af',
        'e6ff8516-1c8d-4a9a-b2db-259f44add6cf',
        '4b22e088-1a61-402c-a331-1b8860931b6a',
        '497da791-d693-4127-88e8-fb6cb91f3f01',
        'e1ed3863-9a57-4d37-82e3-5af3a744a314',
        '9cbaea70-fc20-414e-9f02-984fb86fd5df',
        '5dbf8a56-4c0c-4f6b-b6d6-9d2150bf8457',
        '760ae337-f1b0-47dc-9433-40f842d47adc',
        'aedcfca4-473f-4e4a-892c-f74e76025ff8',
        'f10dac6d-fdfc-4a79-b99f-66779580831a',
        'c19efad9-ac0f-4ba0-a923-d878b2d90aca',
        '94a44236-0bd1-4747-9a92-66a6aa8740eb',
        '6438e33e-f964-4bc8-ba48-a98894c75073',
        '7e7a0dac-3598-4304-9f38-169b0eb5bfb3',
        '83a3d769-20ab-4d4d-8006-9b3dce269a37',
        '59db6488-93d8-49e8-bcbd-931c1ca5f2e8',
        '3b5ffc60-b4cd-4129-b256-07bbc7d1363e',
        '2e958620-9ec8-49bd-ab0f-2cd013d76bea',
        'f357dfa2-4dab-4f9b-bca5-d63ad1b0e0e4',
        '53997f86-6a0e-4701-b278-0c68c8fee412',
        'de3b5b9c-35a2-434f-93a5-1b02a419b21f',
        '902a1310-ef28-4385-bc70-cb9e0c9ecda7',
        '0dd6f54b-63b4-469e-a249-56011335ebfb',
        '361c1d2a-3a76-424a-83ba-d3ae1bd57fb2',
        'fa49a1f0-24b1-4ebd-b2bb-447a7064c2c3',
        'a10e6520-12a4-4924-8b68-e1ff9a2c559f',
        'd61ea82f-c5d2-41b0-836d-58184e16d8d3',
        '83d0b697-a9cf-48bb-9886-ef028ea3d485',
        '8304e4a7-cc55-4bdd-9640-de01f0dbe604',
        '6ed71edb-0cf5-43c7-ad5d-619f6181b6f9',
        '11347703-b1f2-4dd8-bcf7-18541b321571',
        '915febb9-4221-4be3-ada7-e27c242f782b',
        '600efa2c-f412-4c95-a373-acb34b1bfa46',
        '43795327-44bf-457c-8882-8e5523eac8dc',
        '47caabfc-c0eb-4d10-8da2-46f329f5df1b',
        '456ee7bb-828e-466b-bfc2-7affb970ab03',
        '83a8c56e-c8d3-4b19-bd32-4df83eb2013d',
        '7448f57d-4520-47e6-9006-ed52d2a213ea',
        'ffa7a677-6b07-4c3b-bec8-990deef77385',
        '966d8123-689c-4670-aaf5-b9c3e9397d74',
        '5830143c-33cd-4e09-84e2-87f8aea643c7',
        '4e8cd90d-89cc-4032-9fa4-1e235cd387bc',
        '327d8812-c7e0-4ade-a9c9-f2f955579622',
        '1290a8d2-9863-4984-b8a5-06717a11f9bb',
        'bf0e6b83-7828-4df3-964d-3eed1b36c7ed',
        '61491846-f139-41bc-97fc-9840a89ecabb',
        'afa592b6-feee-4686-9394-e7743e97a5b6',
        '400d1b3d-4627-466a-ac75-9ec9b45d4224',
        'e7c351a0-81c0-413f-95fa-e48225ecbdea',
        '8c11f0db-2bb8-46e6-bb34-568c1a1a8e7d',
        'f8e816cc-7033-4b89-9352-9f74f63ec624',
        '5a5bf625-46aa-4126-aea5-e9e762ebf23a',
        '2a988636-a2b5-4cd7-a974-7ef4d48c4977',
        '42a874df-f60f-434e-90a0-e9cf2cec8c65',
        '0067b040-fb6d-44c3-9c7e-43bf5fc5396f',
        '4fd65087-c0e7-484c-89da-bd8895aaf128',
        '8e1ab251-5349-4b11-809c-faeb62d9f3fd',
        '45ae472f-68f7-46bc-b2ca-a37de55072ff',
        '8c319f78-23e8-4066-8402-e161a7a1c711',
        '886f2328-62cd-40bb-8d65-3a213273fc35',
        '1ee2cff2-6a03-45fd-aea9-e260f7d657b8',
        'd1df34e7-6ca9-4520-9aa9-f1c4e1fc6873',
        'd5ff3537-66dc-4f0c-a24a-e88a6c8d18c4',
        '46ddbe5b-5dee-41df-a176-14410c2e57a7',
        'd4b2a7f4-4c31-4b42-a502-5db5dc4703c9',
        'ecddbc3d-bbee-4ff8-bc04-9d6701f8dcba',
        'ac0d6561-cb81-40cc-8873-9b04875f3767',
        'f1e59b01-5a76-43c4-aa52-47c3d6e09fe9',
        'a8459e3a-963c-422c-af46-7d071ac1c36d',
        '4ddec133-bb0e-4fb1-8f79-1a7a1cf980cb',
        'f77a335a-d606-4e12-8451-889b3c14d55e',
        'c6080b69-38f1-4bf7-8e95-d814ec133d47',
        '6be47720-3ab3-403e-b37f-7455fc4692fd',
        '87507fa9-6905-4ba0-b7e0-5aaf7bf1106c',
        '07766471-c3dd-4e06-9434-749ef04cf8c4',
        '62c3202d-564c-4894-abc7-a21dd0dbdaab',
        'fb7d352f-a5aa-411f-a985-76fba0979185',
        '9170c64e-61e7-4b70-a6d3-56febfcceb0e',
        'f9f74edc-1952-4b92-bde4-675bcaa1f235',
        '75ccd031-3fee-441a-82af-5c652b2f7567',
        'a9c968a1-37e0-4369-9554-760ad818373e',
        '5fa30907-a524-446e-9133-9058b1d05db3',
        '9ad7bc7a-02dd-490f-942a-9bf58ef549f3',
        '9898dfba-d8f8-43a8-a029-bbf64af59e92',
        '3d02de43-11bb-47be-884b-7b82cad68b6b',
        'f0894ae4-f20e-4b53-9593-16ec501d2ef8',
        '940d8abe-74b6-468f-a262-539fea87529c',
        '920aa45e-7969-4fc9-8b8a-174a56698afe',
        'bec95db9-e964-40d0-ab4c-4c398e3e6e44',
        'fc8c8199-4330-4b9f-a91f-ff5c7b8584dd',
        '27725087-daf6-4b11-b905-86c15d769eec',
        'bd5a5368-6cf8-4fc8-818a-45e19990979b',
        '2f5eb790-71a3-4153-9993-870106e4eb9b',
        '728ae26f-dd55-406b-91c6-1256478abd09',
        '91bf1bbd-bde8-4eea-9cb5-6a535b6b9839',
        'f0170ba3-2d97-4cb5-b9c7-c6e6bda09aa1',
        '6f4bcf35-79e2-4962-84ca-39403cff48bb',
        'ba5a8b77-781d-4621-aa27-5ae6b1301f78',
        '46d91d89-6b8b-4302-ab64-4e3f657fddb3',
        '7ae1b1c2-88f8-4ef4-8fa3-152dec021086',
        '5c8c2547-6200-4732-88eb-859a1cd63966',
        'dbcebe9a-8ed9-4626-80bf-6d2613d1b1bb',
        'daef4f5a-ecb4-4b69-81f8-9049072d54f6',
        '23355c87-a918-46bf-9d3d-0b948bb24b36',
        '9b6c0bca-19e6-427a-8be3-48e506ecbbe6',
        '4c1f10fa-d4a2-4a93-920c-60d5867c16d3',
        '212c74b5-a2d8-476f-bff7-65b188f029d5',
        '5ac8f48a-b9c7-4847-9d06-75cc859303e9',
        '017ac111-4b38-43d0-891c-03be132d120a',
        'c7b70860-2ee5-4929-af6d-ed2b2fb96708',
        '8a87e4b8-6ea0-4b31-9380-87f92fb487ed',
        'da429ec3-00c7-4de3-b94f-dc29f2e081a4',
        '618bd56a-a0f8-4a47-a70a-ec3e402497db',
        '91e5f46f-42b9-409d-94f1-6a26822ae63e',
        '6073d228-f0e1-469d-b706-76945e807615',
        '9f4714d4-1b42-4f75-b3ea-588afe4b8168',
        'aa18f9ae-623d-4b46-b788-3c59dd194b14',
        'f7aabc5e-0906-4218-943b-984ca1e5612d',
        'a7596bcd-5aa3-4804-9fd0-610ea5153ee9',
        'b89eb942-05fc-4bc8-bd29-c276cde9e991',
        'b04d4acd-0de4-48c5-b894-e5e1cc53ed41',
        '0b03f524-cef0-4fc9-bbc7-2b7a6ffaf85d',
        '321a483b-bca2-4ddb-b694-dc21338a98bb',
        '65cdaf01-5421-4758-a733-7724791f2616',
        'f200e1ff-6f3c-4a82-a9b1-a5e7f763464b',
        'c12608db-25f6-44b6-a4d5-a98b69fe8332',
        'dcd5d4a6-5991-48e3-b01c-726b1896f757',
        '8d13d67e-91dd-4ce2-9eca-81f542e40fc6',
        '489a01a6-b2b2-40e6-83f4-f9bb505bdf91',
        'c1b1b524-38eb-4eee-acc7-1e866fac97ec',
        '6bd28f46-85bc-4fea-b2c0-99b3aca9a648',
        '25f7fb53-8f5f-4892-8c05-7b0c8ce99ae9',
        'feb9e275-a2ce-46a2-b7b2-1dfc9b289534',
        '27a58bbe-908f-4a09-a69c-aa706bcd253a',
        '2f06e115-0f42-48e6-9bba-4de96c0615bd',
        '300c7d70-7dd8-4263-be04-095853a19424',
        '1bd367f5-066d-4270-93a1-3a22bbf3c90c',
        '67bfdf25-b1ea-4139-8985-b64b43cc8fa7',
        '5074a36f-a338-49f4-a2bc-069057b11e2f',
        '55e3da1c-923b-4bb6-ba02-2f8c54a54c1d',
        '465f0dbd-1181-4654-837c-ac445f95e59e',
        'a978ae13-d138-4d9b-b751-ddcf7b7f925d',
        'dc5e22b3-1b35-4707-97ae-f8c1959676e0',
        '60dcbefd-bf45-4357-bfe1-1244983a1608',
        'e1f340be-6e70-49c4-8364-68e51f25157f',
        '7d06bd97-90ab-4fb2-9d21-8b9efd61b34d',
        'f9c207d3-5c28-44f9-9bfa-7ad621358bc1',
        'e4c36359-822e-4976-99a6-6f81e7e6e281',
        'ef11ce50-471e-4fa3-9e22-b2a4f326288d',
        '10c6c4c2-3e1d-4ffd-8691-b19924c52362',
        '145c287a-d50f-48dd-9972-cbf33c5ae850',
        'd38352ad-e70c-4613-ad73-b63f77c3b8a9',
        '8de1ddca-33ab-4072-b557-fea8f77f28a5',
        'af6ca6a6-a549-4dbb-be4f-1675ae2ecbcd',
        '65757a31-8642-4724-8a73-d628c8507479',
        '8504d250-cdb4-45a1-9491-166d418b3f86',
        '3dc17242-f4de-4d2b-8cb7-5249b1f441ea',
      ]

      if (merchantIds.includes(merchantId)) {
        dispatch(updateParams({
          ...params,
          reloadGateway: true,
        }))

        // reset merchant payment gateway
        dispatch({
          type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
          payload: {
            paymentMethod: CREDIT_CARD,
            gateway: PaymentGateway.PAYMENT_GATEWAY_FISERV,
          },
        })
      }

      await Promise.all([
        dispatch(actions.landing.init()),
        dispatch(actions.merchant.fetchMerchant(merchantId)),
        dispatch(actions.merchant.findOfflineDevice(merchantId, true))
          .then(hasOfflineDevice => {
            dispatch(actions.app.toggleMaterDeviceOfflineAlert(hasOfflineDevice))
          }),
      ])
      logger.log('[restaurantInit][2/6] fetchMerchant() Done.')

      if (params.isD2CWeb) {
        const clientLocales = getState().merchant.data.clientLocales
        const defaultClientLanguage = getState().merchant.data.setting.defaultClientLanguage

        // 根據 merchant 設定的用戶介面語言判斷是否需要更改目前的語言，D2C 中只能選擇 clientLocales 有設定的語言
        if (clientLocales.length > 0 && !clientLocales?.some(clientLocale => {
          return appLang?.startsWith(clientLocale)
        })) {
          // 當目前選擇的語言 (app.lang) 不在 merchant 設定的 clientLocales 中，這邊要幫他選擇預設的語言
          // clientLocale 裡面是 en 或 zh 這種短的語言，這邊要轉成 en-US 或 zh-TW 等
          const langMap = {
            en: 'en-US',
            zh: 'zh-HK',
            'zh-TW': 'zh-TW',
            ja: 'ja',
          }
          const defaultLang = langMap[defaultClientLanguage] ?? 'zh-HK'
          dispatch(actions.app.updateLang(defaultLang))
        }
      }
      // 在會員卡頁面不用 order.init
      const d2cGroupMatch = matchPath(window.location.pathname, { path: '/d2c/:merchantId/membership/:groupId' })
      const groupMatch = matchPath(window.location.pathname, { path: '/membership/:groupId' })
      const isInMembershipPage = d2cGroupMatch || groupMatch
      if (!isInMembershipPage && (!selectedOrder || selectedOrder.merchantId !== merchantId)) {
        await dispatch(actions.order.init())
        logger.log('[restaurantInit][3/6] order.init() Done.')
      } else {
        logger.log('[restaurantInit][3/6] order.init() Skip.')
      }

      await dispatch(actions.order.createShipping())
      logger.log('[restaurantInit][4/6] createShipping() Done.')

      if (!params.isD2CWeb) {
        // 判斷是否為非營業時間來打開 preorder drawer
        await dispatch(openPreorderDrawer(merchantId))
      }

      // 選擇預設付款方式
      dispatch(actions.payment.selectDefaultPayment())
      logger.log('[restaurantInit][6/6] selectDefaultPayment() Done.')

      dispatch(actions.app.updateLoading('restaurantInit', false))
    } catch (error) {
      logger.log(`[restaurantInit] error ${error?.message || error}`, { error })
      if (!params.isD2CWeb) {
        // !d2c error handling
        if (error?.response?.status === 404) {
        // 找不到餐廳，回到 restaurant list
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_not_found.title'),
            message: i18n.t('app.component.alert.restaurant_not_found.message'),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: i18n.t('app.common.back'),
              onClick: () => {
                history.replace('/restaurants')
              },
            },
          }))
        } else {
        // 其他錯誤，顯示餐廳初始失敗，回到餐廳列表
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_init_failed.title'),
            message: i18n.t('app.component.alert.restaurant_init_failed.message'),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: i18n.t('app.common.back'),
              onClick: () => {
                history.replace('/restaurants')
              },
            },
          }))
        }
      } else {
        // d2c error handling
        if (error?.response?.status === 404) {
        // 找不到餐廳，停在原地
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_not_found.title'),
            dialogProps: { disableBackdropClick: true },
            buttons: [],
          }))
        } else {
          // 其他錯誤，顯示餐廳初始失敗，重新載入
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.restaurant_init_failed.title'),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: i18n.t('app.common.back'),
              onClick: () => {
                history.go(0)
              },
            },
          }))
        }
      }
    } finally {
      dispatch(actions.app.updateLoading('backdrop', false))
      timer.timeEnd('restaurantInit')
    }
  }
}

/**
 * @param {string} loading
 * @param {boolean} open
 * @returns {ThunkFunction}
 */
export function updateLoading (loading, open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { loading, open },
    })
  }
}

/**
 * @param {passcode} passcode
 * @returns {ThunkFunction}
 */
export function loginSetting () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_IS_LOGIN_SETTING,
      payload: { isLoginSetting: true },
    })
  }
}

/**
 * 僅更改 api 語言、時間顯示語言、UI 語言，若需要讓菜單和訂單內容套用新的語言需要重新 fetchCategories, getOrders
 * @param {string?} lang
 * @returns {ThunkFunction}
 */
export function updateLang (lang) {
  return async (dispatch, getState) => {
    const isD2CWeb = getState().app.params.isD2CWeb
    const broswerLang = navigator.language || navigator.userLanguage

    const i18nextLng = lang != null ? lang : broswerLang
    const apiLanguage = i18nextLng.slice(0, 2) // 'zh-TW' => 'zh'

    // update default language
    if (isD2CWeb) {
      localStorage.setItem(StorageKey.D2C_MODE_LANG, i18nextLng)
    } else {
      localStorage.setItem(StorageKey.LANG, i18nextLng)
    }

    // update api language
    dimorderApi.setLanguage(apiLanguage)
    landingApi.setLanguage(apiLanguage)

    // update i18n language
    i18n.changeLanguage(i18nextLng)

    // update moment language
    moment.locale(i18nextLng.toLocaleLowerCase())

    // update customer language
    const isLogin = Boolean(getState().user.member?.id)
    if (isLogin) {
      dispatch(actions.user.updateCustomerInfo({ locale: apiLanguage }))
    }

    // update redux state
    dispatch({
      type: ActionTypes.UPDATE_LANG,
      payload: { lang: i18nextLng },
    })
  }
}

/**
 * @param {IAlertConfig?} alertConfig
 * @param {string?} id
 * @param {boolean?} open
 * @returns {ThunkFunction}
 */
export function toggleAlert (alertConfig, id, open = true) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_ALERT,
      payload: {
        id: id || uuid(),
        open,
        alertConfig,
      },
    })
  }
}

/**
 * @param {keyof IDrawer} drawer
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function toggleDrawer (drawer, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DRAWER,
      payload: { drawer, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetDrawers () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DRAWERS,
    })
  }
}

/**
 * @param {keyof IDialog} dialog
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function toggleDialog (dialog, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DIALOG,
      payload: { dialog, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetDialogs () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DIALOGS,
    })
  }
}

/**
 * @param {keyof ISnackbar} snackbar
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function toggleSnackbar (snackbar, open, data) {
  return (dispatch, getState) => {
    const isOpen = getState().app.snackbars.orderTracking
    if (open === isOpen) return
    dispatch({
      type: ActionTypes.TOGGLE_SNACKBAR,
      payload: { snackbar, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetSnackbar () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.REST_SNACKBAR,
    })
  }
}

/**
 * @param {keyof IPopover} popover
 * @param {boolean?} open
 * @param {IModal.data} data
 * @returns {ThunkFunction}
 */
export function togglePopover (popover, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_POPOVER,
      payload: { popover, open, data },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetPopover () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_POPOVER,
    })
  }
}

/**
 * @param {PropertyPath} path
 * @param {any} setting
 * @returns {ThunkFunction}
 */
export function updateSetting (path, setting) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SETTING,
      payload: { path, setting },
    })
  }
}

/**
 * @param {string?} url
 * @returns {ThunkFunction}
 */
export function restoreParams (url) {
  logger.log(`[restoreParams] url: ${url}`)
  return async (dispatch, getState) => {
    let storageParams = JSON.parse(localStorage.getItem('params')) ?? {}
    // CA-951，每次進來 APP 要重設 datetime，因此不還原 datetime
    delete storageParams.datetime
    let stateParams = getState().app.params

    let urlParser = null
    let urlParams = {}

    try {
      urlParser = await URLParser.create(url)
      urlParams = urlParser.getParams()
      logger.log('[restoreParams] urlParser', { urlParser, urlParams })
    } catch (error) {
      logger.error(`[restoreParams] URLParser error ${error?.message || error}`, { error })
    }

    if (urlParams.init) {
      // 網址有帶 init 時不還原任何 params
      stateParams = {}
      storageParams = {}
    }

    if (
      (urlParams.table && stateParams.table && urlParams.table !== stateParams.table) || // 網址有帶桌號 id，但與 redux 不一致，不還原 params
      (urlParams.orderId && stateParams.orderId && urlParams.orderId !== stateParams.orderId) // 網址有帶 order id，但與 redux 不一致，不還原 params
    ) {
      stateParams = {}
    }

    if (
      (urlParams.table && storageParams.table && urlParams.table !== storageParams.table) || // 網址有帶桌號 id，但與 localStorage 不一致，不還原 params
      (urlParams.orderId && storageParams.orderId && urlParams.orderId !== storageParams.orderId) // 網址有帶 order id，但與 localStorage 不一致，不還原 params
    ) {
      storageParams = {}
    }

    // redux 的餐廳和 url 相同才能還原
    const allowRestoreReduxState = !urlParser.merchantId || stateParams.merchantId === urlParser.merchantId
    if (!allowRestoreReduxState) {
      // 不允許還原，清除 storageParams
      stateParams = {}
    }

    if (stateParams.isD2CWeb && !urlParser.get('isD2CWeb')) {
      // state 是 d2c 但 url 不是 d2c 不允許還原，清除 stateParams
      stateParams = {}
    }

    // storage 的餐廳和 url 相同才能還原
    const allowRestoreLocalStorage = !urlParser.merchantId || storageParams.merchantId === urlParser.merchantId
    if (!allowRestoreLocalStorage) {
      // 不允許還原，清除 storageParams
      storageParams = {}
    }

    if (storageParams.isD2CWeb && !urlParser.get('isD2CWeb')) {
      // storage 是 d2c 但 url 不是 d2c 不允許還原，清除 storageParams
      storageParams = {}
    }

    if (!allowRestoreReduxState && !allowRestoreLocalStorage) {
      // 不允許還原，清除 localStorage 中的 batchStorage
      dispatch(actions.orderBatch.resetBatch())
    }

    const params = {
      ...initialParams,
      ...stateParams,
      ...storageParams,
      ...urlParams,
    }

    // init 用完就可以刪了，不用留在 params
    delete params.init
    urlParser.searchParams.delete('init')

    if (params.categoryTag === '') {
      delete params.categoryTag
      urlParser.searchParams.delete('categoryTag')
    }

    logger.log('[restoreParams] params', { params })
    dispatch(updateParams(params))

    vConsole.init()

    if (urlParams.lang) {
      // 網址中有指定語言
      const langMap = {
        en: 'en-US',
        zh: 'zh-HK',
        'zh-TW': 'zh-TW',
        ja: 'ja',
      }
      dispatch(updateLang(langMap[urlParams.lang] ?? urlParams.lang))

      // 語言已經存到 storage，不用留在網址，避免 user 更改語言後 refresh 又變回網址指定的語言
      delete urlParams.lang
      urlParser.searchParams.delete('lang')
    } else {
      // 沒指定就從 storage 還原根據是不是 D2C 模式，還原語言設定
      const i18nextLng = localStorage.getItem(params.isD2CWeb ? StorageKey.D2C_MODE_LANG : StorageKey.LANG)
      // 沒有抓到就不要 restore
      if (i18nextLng) {
        dispatch(updateLang(i18nextLng))
      }
    }

    if (params.isD2CWeb) {
      // 這兩個 params 網址已經有了，不用留著
      urlParser.searchParams.delete('merchantId')
      urlParser.searchParams.delete('isD2CWeb')
      const newSearchParams = urlParser.searchParams.toString()
      if (newSearchParams !== window.location.search.replace('?', '')) {
        history.replace(`/d2c/${params.merchantId}?${newSearchParams}`)
      }
    } else {
      const newSearchParams = urlParser.searchParams.toString()
      if (newSearchParams !== window.location.search.replace('?', '')) {
        history.replace(`/?${newSearchParams}`)
      }
    }

    if (params.promoCodeId) {
      // 如果有帶 promoCodeId，前往 coupon page
      if (params.isD2CWeb) {
        // 拿掉 promoCodeId，已經存到 redux 了
        urlParser.searchParams.delete('promoCodeId')
        history.push(`/d2c/${urlParser.merchantId}/member/coupon?${urlParser.searchParams.toString()}`)
      } else {
        history.push(`/member?${urlParser.searchParams.toString()}`)
        history.push(`/member/coupon?${urlParser.searchParams.toString()}`)
      }
      return
    }

    // 前往指定的網址
    if (history.location.pathname !== urlParser.url.pathname) {
      history.push(`${urlParser.url.pathname}?${urlParser.searchParams.toString()}`)
    }
  }
}

/**
 * store redux app.params to localstorage
 * @returns {ThunkFunction}
 */
export function storeParams () {
  return (dispatch, getState) => {
    const params = { ...getState().app.params }

    // CA-951，每次進來 APP 要重設 datetime，因此不存 datetime
    delete params.datetime

    localStorage.setItem('params', JSON.stringify(params))
  }
}

/**
 * @param {IParams} params
 * @returns {ThunkFunction}
 */
export function updateParams (params) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_PARAMS,
      payload: { params },
    })

    dispatch(storeParams())
  }
}

/**
 * @param {boolean} isD2CWeb
 * @returns {ThunkFunction}
 */
export function updateIsD2CWeb (isD2CWeb) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_IS_D2C_WEB,
      payload: { isD2CWeb },
    })

    dispatch(storeParams())
  }
}

/**
 * delete redeem code in params
 * @returns {ThunkFunction}
 */
export function deleteRedeemCode () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.DELETE_REDEEM_CODE,
    })

    dispatch(storeParams())
  }
}

/**
 * delete promoCodeId code in params
 * @returns {ThunkFunction}
 */
export function deletePromoCodeId () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.DELETE_PROMO_CODE_ID,
    })

    dispatch(storeParams())
  }
}

/**
 * @param {IDatetime} datetime
 * @returns {ThunkFunction}
 */
export function updateDatetime (datetime) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_DATETIME,
      payload: { datetime },
    })

    dispatch(storeParams())
  }
}

/**
 * @param {TDeliveryType} deliveryType
 * @returns {ThunkFunction}
 */
export function updateDeliveryType (deliveryType) {
  return (dispatch, getState) => {
    const prevDeliveryType = getState().app.params.deliveryType

    if (deliveryType !== prevDeliveryType) {
      dispatch({
        type: ActionTypes.UPDATE_DELIVERY_TYPE,
        payload: { deliveryType },
      })

      // 清除訂單
      dispatch(actions.order.resetOrder('updateDeliveryType'))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function exitDineIn () {
  return (dispatch, getState) => {
    const params = getState().app.params

    dispatch(updateParams({
      ...params,
      orderId: undefined,
      table: undefined,
    }))

    dispatch(actions.orderBatch.resetBatch())
    dispatch(actions.order.createLocalOrder())
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetOrderId () {
  return (dispatch, getState) => {
    const params = getState().app.params

    dispatch(updateParams({
      ...params,
      orderId: undefined,
    }))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetTable () {
  return (dispatch, getState) => {
    const params = getState().app.params

    dispatch(updateParams({
      ...params,
      table: undefined,
      orderId: undefined,
    }))
  }
}

/**
 * @param {string} table
 * @returns {ThunkFunction}
 */
export function updateTable (table) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_TABLE,
      payload: { table },
    })

    dispatch(storeParams())
  }
}

/**
 * @param {Boolean} open
 * @returns {ThunkFunction}
 */
export function openQRCodeScanningScreen (open) {
  // 掃描時需將 body 設為透明，因為 WebView 蓋在 Native 掃描器上面
  window.document.body.style.background = open ? 'transparent' : 'white'
  return {
    type: ActionTypes.UPDATE_SCANNING,
    payload: { isQRCodeScanning: open },
  }
}

/**
 * @returns {ThunkFunction}
 */
export function openQRCodeScanner () {
  return async (dispatch) => {
    if (!Capacitor.isNativePlatform()) {
      // open QrReaderDrawer
      dispatch(toggleDrawer('qrReader', true))
    } else {
      // open native scanner

      // check permission
      const status = await BarcodeScanner.checkPermission({ force: true })
      if (status.granted) {
        dispatch(openQRCodeScanningScreen(true))

        // start scanning and wait for a result
        const result = await BarcodeScanner.startScan()

        dispatch(openQRCodeScanningScreen(false))

        // if the result has content
        if (result.hasContent) {
          dispatch(handleQRCodeData(result.content))
        } else {
          dispatch(toggleAlert({
            title: i18n.t('app.component.alert.scan_qr.title'),
            message: i18n.t('app.component.alert.scan_qr.message'),
          }))
        }
        return
      }

      logger.log(`openQRCodeScanner camera permission granted: ${status.granted}`, { status })

      if (status.denied || status.neverAsked) {
        // not allow, ask again
        const confirmResult = await Dialog.confirm({
          title: i18n.t('app.component.alert.camera_not_accessible.title'),
          message: i18n.t('app.component.alert.camera_not_accessible.message'),
        })
        logger.log(`openQRCodeScanner camera permission confirm: ${confirmResult?.value}`, { confirmResult })
        if (confirmResult.value) {
          BarcodeScanner.openAppSettings()
        }
      }
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function closeQRCodeScanner () {
  return (dispatch, getState) => {
    if (Capacitor.getPlatform() === 'web') {
      const isOpened = getState().app.drawers.qrReader.open
      if (isOpened) {
        dispatch(toggleDrawer('qrReader', false))
      }
    } else {
      BarcodeScanner.stopScan()

      dispatch(openQRCodeScanningScreen(false))
    }
  }
}

/**
 * @param {string} data
 * @returns {ThunkFunction}
 */
export function handleQRCodeData (data) {
  return async (dispatch, getState) => {
    if (data.startsWith('APP=')) {
      const appMode = data.split('APP=')[1] === 'true'
      dispatch(updateDebugMode(appMode))
    }
    if (data.startsWith('DEBUG_MODE=')) {
      const debugMode = data.split('DEBUG_MODE=')[1] === 'true'
      dispatch(updateDebugMode(debugMode))
    }
    if (data.startsWith('OVERWRITE_ENV=')) {
      const changeEnv = data.split('OVERWRITE_ENV=')[1]
      dispatch(overwriteEnv(changeEnv))
    }
    if (data.startsWith('OVERWRITE_DEPLOYMENT=')) {
      const changeDeployment = data.split('OVERWRITE_DEPLOYMENT=')[1]
      dispatch(overwriteDeployment(changeDeployment))
    }
    if (URLParser.isValidSite(data)) {
      localStorage.setItem('FROM_IN_APP_QRCODE_SCAN', true)
      await dispatch(restoreParams(data))
      const d2cGroupMatch = matchPath(window.location.pathname, { path: '/d2c/:merchantId/membership/:groupId' })
      const groupMatch = matchPath(window.location.pathname, { path: '/membership/:groupId' })
      if (d2cGroupMatch || groupMatch) {
        // 在會員卡頁面不用 order.init
        return
      }
      dispatch(actions.order.init())
    }
  }
}

/**
 * @param {boolean} appMode
 * @returns {ThunkFunction}
 */
export function updateAppMode (appMode) {
  return async (dispatch, getState) => {
    console.log('updateAppMode', appMode)

    // clear params
    localStorage.removeItem('params')

    localStorage.setItem('APP', appMode)

    // 將 gcp log 剩下還沒送出的 log 都送出才 reload
    await flush()
    // reload
    document.location.href = '/'
  }
}

/**
 * @param {boolean} debugMode
 * @returns {ThunkFunction}
 */
export function updateDebugMode (debugMode) {
  return (dispatch, getState) => {
    console.log('updateDebugMode', debugMode)
    if (debugMode) {
      localStorage.setItem('DEBUG_MODE', debugMode)
      config.debugMode = true
    } else {
      // 取得要保留的內容
      const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
      const address = localStorage.getItem('address')

      // clear location storage
      localStorage.clear()
      localStorage.removeItem('DEBUG_MODE')
      localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
      localStorage.setItem('address', address)
      config.debugMode = false
    }

    timer.option.disabled = !debugMode
  }
}

/**
 * @param {TEnv} env
 * @returns {ThunkFunction}
 */
export function overwriteEnv (env) {
  return async (dispatch, getState) => {
    console.log('overwriteEnv', env)
    if (config.env !== env) {
      // 取得要保留的內容
      const deployment = localStorage.getItem('OVERWRITE_DEPLOYMENT')
      const address = localStorage.getItem('address')

      // clear location storage
      localStorage.clear()

      localStorage.setItem('DEBUG_MODE', true)
      localStorage.setItem('OVERWRITE_ENV', env)
      localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
      localStorage.setItem('address', address)

      // 將 gcp log 剩下還沒送出的 log 都送出才 reload
      await flush()
      // reload
      document.location.href = '/'
    }
  }
}

/**
 * @param {TDeployment} deployment
 * @returns {ThunkFunction}
 */
export function overwriteDeployment (deployment) {
  return (dispatch, getState) => {
    console.log('overwriteDeployment', deployment)
    if (deployment != null) {
      localStorage.setItem('DEBUG_MODE', true)
      localStorage.setItem('OVERWRITE_DEPLOYMENT', deployment)
    } else {
      localStorage.removeItem('OVERWRITE_DEPLOYMENT')
    }

    const [newDeployment, newDeploymentKey] = getDeployment(config.env)
    config.deployment = newDeployment
    config.deploymentKey = newDeploymentKey

    if (newDeployment !== getState().app.codePushPackageMeta?.deployment) {
      dispatch(codePushSync(true))
    }
  }
}

/**
 * @param {boolean} isSticky
 * @returns {ThunkFunction}
 */
export function updateCategoryBarSticky (isSticky) {
  return (dispatch, getState) => {
    const currentSticky = getState().app.isCategoryBarSticky
    if (currentSticky === isSticky) return
    dispatch({
      type: ActionTypes.UPDATE_CATEGORYBAR_STICKY,
      payload: { isSticky },
    })
  }
}

/**
 * @param {number} height
 * @returns {ThunkFunction}
 */
export function updateSafeAreaHeight (height) {
  return (dispatch, getState) => {
    const currentHeight = getState().app.safeAreaHeight
    if (height !== currentHeight) {
      dispatch({
        type: ActionTypes.UPDATE_SAFEAREA_HEIGHT,
        payload: { height },
      })
    }
  }
}

/**
 * @param {boolean} open
 * @returns {ThunkFunction}
 */
export function toggleLoginDrawer (open) {
  return (dispatch, getState) => {
    if (!_.isBoolean(open)) return
    if (open) {
      dispatch(toggleDrawer('login', true))
      dispatch(actions.app.setKeyboardMode('none'))
    } else {
      dispatch(toggleDrawer('login', false))
      dispatch(actions.app.setKeyboardMode('native'))
    }
  }
}

export function getPaymentGateway (merchantId) {
  return async (dispatch) => {
    const paymeGatewayResponse = await axios.get(config.api.dimorderNode + '/c/system/payme_gateway')
    const creditCardGatewayResponse = await axios.get(config.api.dimorderNode + '/c/system/credit_card_gateway')
    const payMeGateway = _.get(paymeGatewayResponse, 'data.gateway', PaymentGateway.PAYMENT_GATEWAY_2C2P)
    const creditCardGateway = _.get(creditCardGatewayResponse, 'data.gateway', PaymentGateway.PAYMENT_GATEWAY_FISERV)
    logger.log(`[getPaymentGateway] payme: ${payMeGateway}, creditCard: ${creditCardGateway}`)
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
      payload: { paymentMethod: PAY_ME, gateway: payMeGateway },
    })
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT_GATEWAY,
      payload: { paymentMethod: CREDIT_CARD, gateway: creditCardGateway },
    })
  }
}

/**
 * 檢查外賣外送是否在 admin setting 裡關掉
 * @returns {ThunkFunction}
 */
export function updateSystemDeliveryTypeEnable () {
  return async (dispatch, getState) => {
    const baseUrl = config.api.dimorderNode
    const {
      isD2CWeb,
      takeaway: isD2CTakeawayEnabled,
      storeDelivery: isD2CStoreDeliveryEnabled,
    } = getState().app.params

    try {
      const [takeawayAdminSettingResponse, storeDeliveryAdminSettingResponse] = await Promise.all([
        axios.get(baseUrl + '/c/system/takeaway'),
        axios.get(baseUrl + '/c/system/storedelivery'),
      ])
      const takeawayAdminSetting = takeawayAdminSettingResponse?.data
      const storeDeliveryAdminSetting = storeDeliveryAdminSettingResponse?.data

      if (!takeawayAdminSetting.enable && (!isD2CWeb || isD2CTakeawayEnabled)) {
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.system_takeaway_disable.title'),
          messages: [
            i18n.t('app.component.alert.system_takeaway_disable.message'),
            takeawayAdminSetting.remark,
          ],
        }))
      }

      if (!storeDeliveryAdminSetting.enable && (!isD2CWeb || isD2CStoreDeliveryEnabled)) {
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.system_storeDelivery_disable.title'),
          messages: [
            i18n.t('app.component.alert.system_storeDelivery_disable.message'),
            storeDeliveryAdminSetting.remark,
          ],
        }))
      }

      dispatch({
        type: ActionTypes.UPDATE_SYSTEM_DELIVERYTYPE_ENABLE,
        payload: {
          deliveryType: TAKEAWAY,
          systemEnable: {
            ...takeawayAdminSetting,
            enable: Boolean(takeawayAdminSetting.enable),
          },
        },
      })

      dispatch({
        type: ActionTypes.UPDATE_SYSTEM_DELIVERYTYPE_ENABLE,
        payload: {
          deliveryType: STORE_DELIVERY,
          systemEnable: {
            ...storeDeliveryAdminSetting,
            enable: Boolean(storeDeliveryAdminSetting.enable),
          },
        },
      })
    } catch (error) {
      console.log('updateSystemDeliveryTypeEnable error', error)
    }
  }
}

/**
 * @param {string} path
 * @param {any} value
 * @returns {ThunkFunction}
 */
export function updateTest (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_TEST,
      payload: { path, value },
    })
  }
}

/**
 *
 * @param {IMarketingAction} action
 * @param {any} payload
 * @returns {ThunkFunction}
 */
export function handleMarketingAction (action, payload) {
  return (dispatch, getState) => {
    logger.log('[handleMarketingAction]', { action, payload })

    // 沒有 payload 不動作
    if (!payload || payload === '') return

    switch (action) {
      case 'MERCHANT': {
        const merchantId = payload
        history.push('/restaurant/' + merchantId)
        break
      }

      case 'CATEGORY': {
        const categoryName = payload
        dispatch(actions.landing.redirectToCategory(categoryName))
        break
      }

      case 'CUISINE': {
        const cuisineName = payload
        dispatch(actions.landing.redirectToCuisine(cuisineName))
        break
      }

      case 'SEARCH': {
        const searchText = payload
        dispatch(actions.landing.updateSearchText(searchText))
        history.push('/search')
        break
      }

      case 'LINK': {
        let url = payload
        if (!url.startsWith('http')) {
          url = 'https://' + url
        }
        openBrowser(url)
        break
      }

      default:
        break
    }
  }
}

/**
 * 當 landing 尚未完成初始設定，先將 marketingAction 暫存，待 landing.init 完成後再處理
 * @param {IMarketingAction} marketingAction
 */
export function addPendingMarketingAction (marketingAction) {
  logger.log('[addPendingMarketingAction]', { marketingAction })
  return {
    type: ActionTypes.ADD_PENDING_MARKETING_ACTION,
    payload: marketingAction,
  }
}

/**
 * 移除完成的 pendingMarketingAction
 */
export function removePendingMarketingAction () {
  logger.log('[removePendingMarketingAction]')
  return {
    type: ActionTypes.REMOVE_PENDING_MARKETING_ACTION,
  }
}

/**
 *
 * @param {boolean} hasOfflineDevice
 * @returns {ThunkFunction}
 */
export function toggleMaterDeviceOfflineAlert (hasOfflineDevice) {
  return (dispatch, getState) => {
    if (hasOfflineDevice) {
      dispatch(toggleAlert({
        title: i18n.t('app.component.alert.merchant_device_offline.title'),
        message: i18n.t('app.component.alert.merchant_device_offline.message'),
        button: {
          text: i18n.t('app.common.back'),
        },
      }))
    }
  }
}

/**
 * 清除 params 和 url 中的 p 和 orderId (領取積分用的 password)
 * @param {string} password
 * @returns {ThunkFunction}
 */
export function removeClaimCRMPointsPassword () {
  return (dispatch, getState) => {
    const params = getState().app.params

    // 移除 p (領取積分用的 password) 和 orderId
    const newParams = { ...params }
    delete newParams.p
    delete newParams.orderId

    // 從 url 中移除 p (領取積分用的 password) 和 orderId
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('p')
    searchParams.delete('orderId')

    dispatch(updateParams(newParams))
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, '', newUrl)
  }
}
