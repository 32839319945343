// eslint-disable-next-line no-unused-vars
import { Skeleton, SkeletonProps } from '@material-ui/lab'
// eslint-disable-next-line no-unused-vars
import { Typography, TypographyProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import clsx from 'clsx'

import { transformation } from '@/libs/cloudinary'

const CARD = { borderRadius: 10, shadow: '0px 1px 4px 0px #00000040' }
const CARD_IMAGE = { width: 40, height: 40 }

/**
 *
 * @param {React.HTMLAttributes<HTMLDivElement> & { component?: React.FC }} props
 * @returns
 */
export default function Card (props) {
  const { className, component, ...rest } = props
  const classes = useStyles(props)
  const Component = component || DefaultComponent
  function DefaultComponent (props) {
    return <div {...props}>{props.children}</div>
  }
  return <Component className={clsx(classes.card, className)} {...rest}>{props.children}</Component>
}

/**
 *
 * @param {React.HTMLAttributes<HTMLDivElement> & { imageUrl: string, size?: numebr }} props
 * @returns
 */
export function CardImage (props) {
  const classes = useStyles(props)
  const { className, imageUrl, size, ...rest } = props
  const url = transformation(imageUrl, { width: 128 })
  const cardSize = size ? { width: size, height: size } : { width: CARD_IMAGE.width, height: CARD_IMAGE.height }
  return <div className={clsx(classes.cardImage, className)} style={{ backgroundImage: `url(${url})`, width: cardSize.width, height: cardSize.height }} {...rest} />
}

/**
 *
 * @param {TypographyProps & { title: string }} props
 * @returns
 */
export function CardHeader (props) {
  const classes = useStyles(props)
  const { className, title, ...rest } = props
  return <Typography variant='subtitle2' className={clsx(classes.cardHeader, className)} {...rest}>{title}</Typography>
}

/**
 *
 * @param {SkeletonProps} props
 * @returns
 */
export function CardSkeleton (props) {
  const { className, ...rest } = props
  const classes = useStyles(props)
  return <Skeleton variant='rect' className={clsx(classes.cardSkeleton, className)} {...rest} />
}

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: CARD.shadow,
    borderRadius: CARD.borderRadius,
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(0, 1),
  },
  cardImage: {
    flexShrink: 0,
    borderRadius: CARD.borderRadius,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.grey[100],
  },
  cardHeader: {
    textAlign: 'left',
    lineHeight: '150%',
    whiteSpace: 'pre-line',
    fontWeight: theme.typography.fontWeightBold,
  },
  cardSkeleton: {
    borderRadius: CARD.borderRadius,
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(0, 1),
  },
}))
